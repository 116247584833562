.home {
  margin-top: 40px;
}

.hotel-hero {
  display: grid;
  grid-template-columns: repeat(1, fr);

  @media screen and (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.hotel-intro-box {
  padding: 20px 20px 1px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.hotel-images-box {
  margin-top: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
}

.hotel-images-box {
  border-radius: 5px;
  overflow: hidden;
}

.hotel-sub-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hotel .hotel-price-box {
  margin-bottom: 10px;
}

.hotel-description {
  margin: 30px 0;

  & > p {
    margin-bottom: 25px;
    font-size: 22px;
  }

  & > p:last-of-type {
    margin-bottom: 35px;
  }
}
