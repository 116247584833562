header {
  min-height: calc(100vh - 111px);
}

.hero {
  display: flex;
  align-items: center;
  margin: 40px 10px;
  flex-direction: column-reverse;

  @media screen and (min-width: 1000px) {
    margin-top: 0;
    flex-direction: row;
  }
}

.hero-text {
  @media screen and (min-width: 1000px) {
    max-width: 400px;
  }
}

.hero-text-heading {
  font-size: 42px;
  margin-bottom: 20px;
  line-height: 1.35;
  text-transform: uppercase;
}

.hero-text-paragraph {
  font-family: var(--font-text);
  font-size: 18px;
}

.hero-img-box {
  z-index: -1;
  margin-left: -15vh;
}

.hero-img {
  width: 100%;
}
