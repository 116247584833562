.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--color-five);
  box-shadow: 0 30px 50px rgba($color: #000000, $alpha: 0.15);
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  z-index: 80;
  padding: 5px 15px;
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }

  &__list {
    align-items: center;
    list-style: none;
    flex-direction: column;
    margin-top: 100px;
    gap: 30px;
    display: none;
    @media screen and (min-width: 1000px) {
      flex-direction: row;
      margin-top: 0;
      display: flex;
      gap: 15px;
    }
  }

  &__item {
    margin: 0 10px;
  }

  &__link {
    position: relative;

    &:link,
    &:visited {
      color: var(--color-five);
      @media screen and (min-width: 1000px) {
        color: var(--color-one);
      }

      padding: 10px 10px;
      text-decoration: none;
      font-size: 22px;
      transition: all 0.2s;
      border-bottom: 1px solid transparent;
    }

    &:hover,
    &:active {
      border-bottom: 1px solid var(--color-three);
    }
  }

  &__auth-list {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__auth-link {
    &:link,
    &:visited {
      color: var(--color-one);
      padding: 10px;
      text-decoration: none;
      font-size: 22px;
      transition: all 0.2s;
      border-bottom: 1px solid transparent;
    }

    &:hover,
    &:active {
      border-bottom: 1px solid var(--color-three);
    }
  }
}

.logo {
  height: 100px;
}

.nav-active:link,
.nav-active:visited {
  border-bottom: 1px solid var(--color-three) !important;
}

// search

.search-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 15px;
  transition: all 0.25s;

  &:hover {
    color: var(--color-three);
  }
}

.ham-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.25s;
  &:hover {
    color: var(--color-three);
  }
  @media screen and (min-width: 1000px) {
    display: none;
  }
}
