.contact-form {
  border: 3px solid var(--color-three);
  padding: 20px;
  @media screen and (min-width: 768px) {
    padding: 30px 50px;
  }
  border-radius: 50px;
  margin: 50px auto;
  max-width: 1200px;
}

.contact-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  .left-side {
    width: 100%;
    padding: 20px 30px;
    @media screen and (min-width: 768px) {
      width: 40%;
    }
  }

  .right-side {
    order: -1;
    @media screen and (min-width: 768px) {
      flex: 0 0 60%;
      order: 1;
    }
  }
}

.post-img {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.contact-label {
  margin-bottom: 5px;
  display: block;
  color: var(--color-one);
  font-size: 18px;
}

.contact-input,
.contact-textarea {
  width: 100%;
  border: 1px solid rgba($color: #f05f62, $alpha: 0.4);
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.2s;
  font-size: inherit;
  font-family: inherit;
  &:focus {
    outline: none;
    border: 1px solid rgba($color: #f05f62, $alpha: 0.8);
  }
  background-color: var(--color-five);
}

.contact-textarea {
  height: 80px;
}

// .contact-submit-btn {
//   display: inline-block;
//   padding: 10px 20px;
//   background-color: var(--color-two);
//   color: var(--color-five);
//   font-size: 20px;
//   border: none;
//   border-radius: 100px;
//   box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.07);
//   cursor: pointer;
// }
