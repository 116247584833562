.login-form {
  max-width: 450px;
  margin: 70px auto;
  margin-bottom: 100px;
}

.login-input {
  width: 100%;
  padding: 7px 10px;
  border: none;
  font-family: inherit;
  font-size: 16px;
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  border-radius: 4px;
  padding-left: 35px;

  &:focus {
    outline: none;
    border: 1px solid rgba($color: #000000, $alpha: 0.4);
  }
}

.input-icon {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}

// .login-submit-btn {
//   cursor: pointer;
//   background-color: var(--color-two);
//   border: none;
//   padding: 10px 20px;
//   box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.07);
//   display: inline-block;
//   width: 100%;
//   color: var(--color-five);
//   font-size: 18px;
// }
