.admin-nav {
  color: #eee;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 15px;
  gap: 10px;

  @media screen and (min-width: 769px) {
    flex-direction: row;
    gap: 0;
  }

  &--outer {
    background-color: rgb(33, 37, 41);
    margin-bottom: 40px;
    padding: 10px 0;
  }
}
.admin-nav p.welcome {
  margin-bottom: 0 !important;
  margin-right: 30px;
}

.admin-nav-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;

  @media screen and (min-width: 769px) {
    flex-direction: row;
    gap: 13px;
  }
}

.admin-nav-item {
  &--auth {
    @media screen and (min-width: 769px) {
      margin-left: auto;
    }
  }
}
.admin-nav-link,
.admin-nav-link:link,
.admin-nav-link:visited {
  text-decoration: none;
  color: #ccc;
  display: inline-block;
  padding: 8px 15px;
  transition: all 0.2s;
  background-color: transparent;
  cursor: pointer;
  border: none;
  text-align: center;
  width: 100%;
}

.admin-nav-link:hover,
.admin-nav-link:active {
  color: #fff;
}

.welcome {
  display: flex;
  align-items: center;
}

.user-name {
  font-size: 12px;
  display: inline-block;
  margin-left: 6px;
}
