.hotels {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
}

.section-hotel {
  padding: 50px 0;
  h3 {
    margin-bottom: 40px;
  }
}

.hotel {
}

.hotel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
