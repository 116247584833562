.search-submit-btn {
  display: block;
  margin: 30px auto;
  font-size: 20px;
  background-color: #ddd;
  color: #222;
  border: none;
  cursor: pointer;
  width: 50%;
  padding: 15px;
}

.search-form {
  padding: 50px;
}

.search-input {
  border: none;
  padding: 15px 30px;
  font-size: 32px;
  font-family: inherit;
  background-color: transparent;
  display: block;
  color: #eee;
  width: 100%;
  border-bottom: 1px solid transparent;
  &:focus {
    outline: none;
    border-bottom: 1px solid #ddd;
  }
}

.autocomplete {
  position: relative;
  margin-top: 25px;
}

.autocomplete-items {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  margin-top: 0;
  width: 100%;
  left: 0;
}

a.autocomplete-link {
  &:link,
  &:visited {
    color: var(--color-five);
    text-decoration: none;
    display: block;
    background-color: var(--color-five);
    color: #222;
    padding: 10px 20px;
    opacity: 0.8;
    transition: all 0.2s;
  }

  &:hover {
    opacity: 1;
  }
}
