*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

:root {
  --color-black: #222;
  --color-one: #3b1924;
  --color-two: #458fcd;
  --color-three: #f05f62;
  --color-four: #f9c0bc;
  --color-five: #fefcfc;
  --font-text: 'Quicksand', sans-serif;
  --font-heading: 'League Spartan', sans-serif;
}

body {
  font-family: var(--font-text);
  font-weight: 400;
  font-size: 16px;
  color: var(--color-one);
  line-height: 1.45;
  min-height: 101vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
}

.loading {
  margin: 50px auto;
  fill: var(--color-one) !important;
  width: 150px !important;
  display: block;
  height: 150px !important;

  &--white {
    fill: #fff !important;
  }
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 4%;
}

.text-center {
  text-align: center;
}

.heading-primary {
  font-size: 48px;
  margin-bottom: 22px;
}

.heading-secondary {
  font-size: 42px;
  margin-bottom: 18px;
}

.heading-tertiary {
  font-size: 36px;
  margin-bottom: 18px;
}

.heading-4 {
  font-size: 28px;
  margin-bottom: 15px;
}

.heading-5 {
  font-size: 20px;
  margin-bottom: 12px;
}

.form-group {
  margin-bottom: 24px;
  position: relative;
}

.d-block {
  display: block !important;
  width: 100%;
}

.main-btn {
  &,
  &:link,
  &:visited {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-five);
    background-color: var(--color-two);
    padding: 10px 20px;
    border-radius: 100px;
    display: inline-block;
    margin-top: 15px;
    text-transform: uppercase;
    transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
    border: 1px solid transparent;
  }

  &:hover,
  &:active {
    color: var(--color-two);
    background-color: var(--color-five);
    border: 1px solid var(--color-two);
  }
}

.bold {
  font-weight: bold;
}

// table

.table {
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  border-collapse: collapse;
  margin: 40px 0;
  width: 100%;
}

.table-content {
  width: 100%;
  overflow-x: auto;
}

.tr {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.7);
}

.th {
  text-align: left;
  font-family: var(--font-heading);
  white-space: nowrap;
}

.td,
.th {
  padding: 6px 12px;
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.td.message {
  min-width: 500px;
}
